<template>
  <Container :padding="false">
    <template v-if="checkResponseStatus && !isLoading">
      <div class="full_image mb_6rem">
        <div v-html="content"></div>
      </div>
    </template>
    <NoData v-else-if="!checkResponseStatus && !isLoading"></NoData>
  </Container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Container from "../components/Container";
import NoData from "@/components/NoData";
export default {
  components: {
    NoData,
    Container,
  },
  data() {
    return {};
  },
  watch: {
    helpData: {
      handler: function(newVal) {
        this.changeSpinnerStatus(true);
        if (newVal) {
          setTimeout(() => {
            this.changeSpinnerStatus(false);
          }, 800);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("help", ["helpData"]),
    content() {
      if (this.helpData.length === 0) return;
      return this.helpData[0].content;
    },
    checkResponseStatus() {
      return this.helpData && this.helpData.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions("help", ["getHelp"]),
    ...mapActions("spinner", ["changeSpinnerStatus"]),
  },
  created() {
    this.getHelp({
      code: "gooleteach",
      page: 1,
      limit: 20,
    });
  },
};
</script>

<style lang="scss" scoped>
.full_image {
  width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
</style>
